<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#121212',
        fontFamily: 'Raleway',
        borderRadius: 12,
        colorError: '#fc0004',
      },
    }"
  >
    <div class="page-wrapper" v-if="!isLoading">
      <router-view />
      <ModalError :error="getError" @onOk="clearError" />
    </div>
    <a-flex justify="center" align="center" style="height: 90vh"  v-if="isLoading">
      <LoadingSpin :loading="isLoading" />
    </a-flex>
  </a-config-provider>
</template>

<script type="ts">
import { mapGetters, mapMutations} from "vuex";
import ModalError from "@/components/ModalError.vue";
import LoadingSpin from "@/components/LoadingSpin.vue";

export default {
  name: 'App',
  components: { LoadingSpin, ModalError },
  methods: {
    ...mapMutations(['clearError'])
  },
  computed: {
    ...mapGetters(['getError', 'isLoading'])
  },
};
</script>
