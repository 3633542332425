import { axiosInstance, config } from "./axiosConfigs/backendConfig";
import { AuthControllerRestApiFactory } from "@/api/clients/backend";
import { newAxiosInstance } from "@/api/axiosConfigs/newBackendConfig";
import { tariffsAxiosInstance } from "@/api/axiosConfigs/calculatorConfig";

const authApiClient = AuthControllerRestApiFactory(
  config,
  undefined,
  axiosInstance
);

export const performLogin = async (email: string, password: string) => {
  const loginModel = {
    email: email,
    password: password,
  };

  return authApiClient.login(loginModel).then((response) => {
    console.log("Успешная авторизация:", response);
    const token = response.data.token;
    if (typeof token === "string") {
      return token;
    } else {
      throw new Error("Invalid token received from server");
    }
  });
};

export const loginWithToken = async (token: string): Promise<void> => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 7);
  document.cookie = `accessToken=${token};expires=${expires.toUTCString()};path=/`;
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  newAxiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  tariffsAxiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
};
