import { axiosInstance, config } from "./axiosConfigs/backendConfig";
import { GetUsersByTypeTypeEnum, RoleModel, UserControllerRestApiFactory, UserModel } from "@/api/clients/backend";
import { RawAxiosRequestConfig } from "axios";
import { EnhancedAuthModel, UserRole } from "@/types/UsersTypes";

const userApiClient = UserControllerRestApiFactory(
  config,
  undefined,
  axiosInstance
);

export const currentUser = async (
  options: RawAxiosRequestConfig = {}
): Promise<EnhancedAuthModel> => {
  try {
    const response = await userApiClient.current(options);
    const userData = response.data;

    return {
      ...userData,
      hasRole: (role: UserRole): boolean => {
        return Array.from(userData.roles ?? []).some((r: RoleModel) => r.id === role);
      }
    };
  } catch (err) {
    console.error("Error getting current user:", err);
    throw err;
  }
};

export const setStock = async (
  stockId: number,
  options: RawAxiosRequestConfig = {}
) => {
  try {
    await userApiClient.selectStock(stockId, options);
  } catch (err) {
    console.error("Error setting stock:", err);
    throw err;
  }
};

export const getAllUsersByType = async (type: GetUsersByTypeTypeEnum) => {
  try {
    const response = await userApiClient.getUsersByType(type);
    return response.data;
  } catch (err) {
    console.error("Error getting all roles:", err);
    throw err;
  }
}

export const getAllRoles = async () => {
  try {
    const response = await userApiClient.list();
    return response.data;
  } catch (err) {
    console.error("Error getting all roles:", err);
    throw err;
  }
}

export const deleteUserAccount = async (id: number) => {
  try {
    await userApiClient.delete2(id);
  } catch (err) {
    console.error("Error deleting user:", err);
    throw err;
  }
}

export const updateUserAccount = async (user: UserModel) => {
  try {
    await userApiClient.save(user);
  } catch (err) {
    console.error("Error updating user:", err);
    throw err;
  }
}

export const blockedAccount = async (id: number) => {
  try {
    await userApiClient.block(id);
  } catch (err) {
    console.error("Error blocking user:", err);
    throw err;
  }
}

export const updateUserPassword = async (userId: number, body: string,) => {
  try {
    await userApiClient.updatePassword(userId, body);
  } catch (err) {
    console.error("Error updating user password:", err);
    throw err;
  }
}
