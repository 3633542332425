import { StockModel } from "@/api/clients/backend";
import { currentUser } from "@/api/UserApi";
import { Commit } from "vuex";
import { EnhancedAuthModel, PermissionRoleName } from "@/types/UsersTypes";

const UserStore = {
  state: {
    currentUser: null as EnhancedAuthModel | null,
  },
  getters: {
    getCurrentUser(state: { currentUser: EnhancedAuthModel | null }): EnhancedAuthModel | null {
      return state.currentUser;
    },
    hasPermission: (state: {currentUser: EnhancedAuthModel | null }) => (permission: string) => {
      return state.currentUser?.allowedPages?.includes(permission) || false
    },
    getStocks(state: {
      currentUser: EnhancedAuthModel | null;
    }): StockModel[] | undefined {
      return state.currentUser?.stocks;
    },
    getCurrentStock(state: {
      currentUser: EnhancedAuthModel | null;
    }): number | undefined {
      return state.currentUser?.selectedStockId;
    },
  },
  mutations: {
    setCurrentUser(state: { currentUser: EnhancedAuthModel | null }, user: EnhancedAuthModel) {
      state.currentUser = user;
    },
  },
  actions: {
    async fetchCurrentUser({ commit }: { commit: Commit }) {
      try {
        const user = await currentUser();
        const permissions = new Set<string>();

        user.roles?.forEach(role => {
          role.permissions?.forEach(({ name }) => {
            const value = (name as unknown as PermissionRoleName)?.value;
            if (value) permissions.add(value);
          });
        });
        commit("setCurrentUser", {
          ...user,
          allowedPages: Array.from(permissions)
        });
      } catch (e) {
        console.error("Failed to fetch current user:", e);
        throw e;
      }
    },
  },
};

export default UserStore;
