import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeView",
    component: () => import("../views/HomeView.vue"),
    meta: {requiresAllow: true, permission: "INCOMING_PARCELS", requiresAuth: true }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
    meta: { public: true }
  },
  {
    path: '/no-access',
    name: 'NoAccess',
    component: () => import('@/views/NoAccessView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/register-incoming-parcel/:id",
    name: "RegisterIncomingParcel",
    component: () => import("../views/RegisterIncomingParcel.vue"),
    meta: {requiresAllow: true, permission: "INCOMING_PARCELS", requiresAuth: true }
  },
  {
    path: "/register-incoming-parcel/successful/:id",
    name: "SuccessfulRegisterIncomingParcel",
    component: () => import("../views/SuccessfulRegisterIncomingParcel.vue"),
    meta: {requiresAllow: true, permission: "INCOMING_PARCELS", requiresAuth: true }
  },
  {
    path: "/new-parcel",
    name: "NewParcel",
    component: () => import("../views/NewParcelView.vue"),
    meta: {requiresAllow: true, permission: "ADD_NEW_PARCEL", requiresAuth: true }
  },
  {
    path: "/outgoing-parcels",
    name: "OutgoingParcels",
    component: () => import("@/views/OutgoingParcelsView.vue"),
    meta: {requiresAllow: true, permission: "OUTGOING_PARCELS", requiresAuth: true }
  },
  {
    path: "/register-outgoing-parcel/:id",
    name: "SuccessfulRegisteredOutgoingParcel",
    component: () => import("../views/SuccessfulRegisteredOutgoingParcel.vue"),
    meta: {requiresAllow: true, permission: "OUTGOING_PARCELS", requiresAuth: true }
  },
  {
    path: "/problem-parcel-final:id",
    name: "ProblemParcelFinalView",
    component: () => import("../views/ProblemParcelFinalView.vue"),
    meta: {requiresAllow: true, permission: "INCOMING_PARCELS", requiresAuth: true }
  },
  {
    path: "/address-storage",
    name: "AddressStorageView",
    component: () => import("../views/AddressStorageView.vue"),
    meta: {requiresAllow: true, permission: "ADDRESS_STORAGE", requiresAuth: true }
  },
  {
    path: "/admin/users",
    name: "UsersView",
    component: () => import("../views/UsersView.vue"),
    meta: {requiresAllow: true, permission: "USERS", requiresAuth: true }
  },
  {
    path: "/admin/roles",
    name: "RolesView",
    component: () => import("../views/RolesView.vue"),
    meta: {requiresAllow: true, permission: "ROLE", requiresAuth: true }
  },
  {
    path: "/admin/create-role",
    name: "CreateRoleView",
    component: () => import("../views/CreateRoleView.vue"),
    meta: {requiresAllow: true, permission: "PERMISSION", requiresAuth: true }
  },
  {
    path: "/relabel",
    name: "RelabelView",
    component: () => import("../views/RelabelView.vue"),
    meta: {requiresAllow: true, permission: "TRANSIT_STOCK", requiresAuth: true }
  },
  {
    path: "/consignments",
    name: "Consignments",
    component: () => import("../views/ConsignmentsView.vue"),
    meta: {requiresAllow: true, permission: 'CONSIGNMENTS', requiresAuth: true }
  },
  {
    path: "/consolidation/:id",
    name: "ConsolidationView",
    component: () => import("../views/consolidation/ConsolidationView.vue"),
    meta: {requiresAllow: true, permission: 'CONSIGNMENTS', requiresAuth: true }
  },
  {
    path: "/dict/space-model/:box",
    name: "DictSpacesView",
    component: () => import("../views/dict/DictSpacesView.vue"),
    meta: {requiresAllow: true, permission: "PLACE", requiresAuth: true }
  },
  {
    path: "/dict/seal",
    name: "DictSealView",
    component: () => import("../views/dict/DictSealView.vue"),
    meta: {requiresAllow: true, permission: "SEAL", requiresAuth: true }
  },
  {
    path: "/consolidation/:id/create/container/:containerId?",
    name: "CreateContainerView",
    component: () => import("../views/consolidation/CreateContainerView.vue"),
    meta: {requiresAllow: true, permission: 'CONSIGNMENTS', requiresAuth: true }
  },
  {
    path: "/consolidation/:id/create/pallet/:palletId?",
    name: "CreatePalletView",
    component: () => import("../views/consolidation/CreatePalletView.vue"),
    meta: {requiresAllow: true, permission: 'CONSIGNMENTS', requiresAuth: true }
  },
  {
    path: "/logs",
    name: "LogsView",
    component: () => import("../views/LogsView.vue"),
    meta: {requiresAllow: true, permission: "LOGS", requiresAuth: true }
  },
  {
    path: "/dict/country",
    name: "CountryDictView",
    component: () => import("../views/CountryDictView.vue"),
    meta: {requiresAllow: true, permission: "COUNTRY_DICT", requiresAuth: true }
  },
  {
    path: "/sandbox/edit-parcel",
    name: "EditParcelView",
    component: () => import("../views/sandbox/EditParcelView.vue"),
    meta: {requiresAllow: false, requiresAuth: true }
  },
  {
    path: "/sandbox/edit-tariffs",
    name: "EditTariffsView",
    component: () => import("../views/sandbox/EditTariffsView.vue"),
    meta: {requiresAllow: false, requiresAuth: true }
  },
  {
    path: "/sandbox/move-recipient-tg-bot",
    name: "MoveRecipientTg",
    component: () => import("../views/sandbox/MoveRecipientTgView.vue"),
    meta: {requiresAllow: false, requiresAuth: true }
  },
  {
    path: "/sandbox/logistics-reports",
    name: "LogisticReports",
    component: () => import("../views/sandbox/LogisticReportsView.vue"),
    meta: { requiresAllow: false, requiresAuth: true }
  },
  {
    path: "/sandbox/edit-consignment",
    name: "EditConsignment",
    component: () => import("../views/sandbox/EditConsignmentView.vue"),
    meta: { requiresAllow: false, requiresAuth: true }
  },
  {
    path: "/sandbox/find-track",
    name: "FindTrack",
    component: () => import("../views/sandbox/FindMyTrackView.vue"),
    meta: { requiresAllow: false, requiresAuth: true }
  },
  {
    path: "/sandbox/currency",
    name: "Currency",
    component: () => import("../views/sandbox/CurrencyView.vue"),
    meta: { requiresAllow: false, requiresAuth: true }
  },
  {
    path: "/parcel-registry",
    name: "ParcelRegistry",
    component: () => import("../views/registry/ParcelRegistryView.vue"),
    meta: { requiresAllow: true, permission: "PARCEL_REGISTRY", requiresAuth: true }
  },
  {
    path: "/result-search",
    name: "SearchResultView",
    component: () => import("../views/SearchResultView.vue"),
    props: (route) => ({ query: route.query }),
    meta: { requiresAllow: true, permission: "INCOMING_PARCELS", requiresAuth: true }
  },
  {
    path: "/ym-gbs-stock",
    name: "YandexGbsStockView",
    component: () => import("../views/YandexGbsView.vue"),
    props: (route) => ({ query: route.query }),
    meta: { requiresAllow: true, permission: "YM_GBS_STOCK", requiresAuth: true }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404View.vue'),
    meta: { public: true }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  store.commit('setLoading', true)
  if (to.meta.public) return next()

  const isAuthenticated = store.getters.isLogin

  if (!isAuthenticated) {
    try {
      await store.dispatch('checkTokenAndLogin')
    } catch (error) {
      return next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
    }
  }

  if (!store.getters.getCurrentUser) {
    try {
      await store.dispatch('fetchCurrentUser')
    } catch (error) {
      await store.dispatch('logout')
      return next({
        path: "/login",
        query: { redirect: to.fullPath },
      })
    }
  }

  if (to.meta.permission && to.meta.requiresAllow) {
    const requiredPermission = to.meta.permission;
    const hasAccess = store.getters.hasPermission(requiredPermission)

    if (!hasAccess) {
      return next('/no-access')
    }
  }

  next()
});

router.afterEach(() => {
  store.commit('setLoading', false)
})

export default router;
