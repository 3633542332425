import { DirectiveBinding, VNode, App } from 'vue';

export default {
  install(app: App) {
    app.directive('sandbox', {
      async mounted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
        const user = await app.config.globalProperties.$store?.getters?.getCurrentUser?.email;

        console.log(user);

        if (!user) return;

        const admin = [
          'admin',
          'alex',
          'pavel.m@parcelpoint.us',
          'sasha.demin@parcelpoint.us',
          'mikhail.lipatov@parcelpoint.us',
          'ceo@parcelpoint.us',
          'amir.z@parcelpoint.us',
          'aleksandra.lukyanchuk@parcelpoint.us',
          'veronika.m@parcelpoint.us',
          'sergei.n@stockfore.us',
          'yevgeniya.t@stockfore.us',
          'maxim.budanov@parcelpoint.us'
        ];

        const isAdmin = admin.some((adminEmail) => adminEmail === user);

        if (!isAdmin) el.remove();
      },
    });
  },
};
